.field {
  width: 100%;
}
input.input {
  border-radius: 8px !important;
}

label.label {
  font-size: 1rem;
}
