.fadein-appear {
  opacity: 0;
  transition: all .5s ease;
}

.fadein-appear.fadein-appear-active {
  opacity: 1;
  transition: all .5s ease;
}

.fade-enter {
  opacity: 0.01;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}


.pulse {
  transform: scale(1);
  animation: pulse-red 2s infinite;
}

@keyframes pulse-red {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(93%, 24%, 37%, 0.7);
	}
	
	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(93%, 24%, 37%, 0);
	}
	
	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(93%, 24%, 37%, 0);
	}
}
