.primary-text {
  color: $primaryTextColor !important;
}

.secondary-text {
  color: $secondaryTextColor !important;
}

.bg-gray {
  background-color: $backgroundGrayColor !important;
}