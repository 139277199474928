@charset "utf-8";
// @import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,200;0,300;0,400;0,500;1,100;1,200;1,300;1,400&display=swap');
@import "animations.scss";
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;1,200;1,300&display=swap");

*:not(i) {
  font-family: "Poppins" !important;
}

$aqua: #66d7d1;
$blueDark: #1e3959;
$orange: #fc7753;
$darkGreen: #2c617a;
$backgroundColor: #f8f8fe;
$backgroundGrayColor: #f4f6fa;
$grey-light: rgb(172, 172, 172);
$grey: #6e7191;
$doneTask: #3df2bf;
$primaryTextColor: #002962;
$secondaryTextColor: #6a7a8a;
// $white-ter: #38406a;
// Update Bulma's global variables
$primary: $blueDark;
$link: $aqua;
$info: $orange;
$widescreen-enabled: true;
$fullhd-enabled: true;
$dark: #000521;
$text: $blueDark;

$grey-lighter: #f8f8fe; 

// Bulma tooltips
$tooltip-radius: 8px;
$tooltip-background-opacity: 1;
$tooltip-background-color: $primaryTextColor;
$animation-transition-timing-function: ease;

@import "colors.scss";
[data-tooltip]:not(.is-loading)::before,
[data-tooltip]:not(.is-disabled)::before,
[data-tooltip]:not([disabled])::before {
  transition: all 0.3s ease;
}

body,
html {
  background-color: $backgroundColor !important;
}

.blue-gradient {
  background: linear-gradient(
    to right,
    rgb(41, 128, 185),
    rgb(109, 213, 250),
    rgb(255, 255, 255)
  );
}

div:not(.tag),
p .title {
  color: $primaryTextColor;
}

.title {
  color: $primaryTextColor !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $primaryTextColor;
}

hr.navbar-divider {
  background-color: transparentize($color: $primary, $amount: 0.8);
}

.title {
  &.is-1 {
    &.is-large {
      font-size: 90px !important;
    }
  }
}

.is-borderless {
  border: none !important;
  box-shadow: none;

  &.navbar-dropdown {
    box-shadow: 0 8px 8px rgba(10, 10, 10, 0.1) !important;
  }
}

// Buttons
.button {
  transition: all 0.1s ease;
  border-radius: 8px !important;
  &.is-circle {
    border-radius: 100% !important;
  }
}

label {
  color: $primary !important;
}

.is-transparent {
  background: transparent !important;
}

.is-truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.is-overflowed {
  overflow: scroll;
}
.notification {
  border-radius: 8px !important;
}

.has-link-shadow {
  box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.4) !important;
}

.panel-shadow {
  box-shadow: 0px 1px 20px 0px #c1c1c14d;
}
.z-index-1 {
  z-index: 1 !important;
}
.navbar.secondary {
  box-shadow: rgb(202 201 201 / 27%) 0px 5px 4px 2px;

  a {
    color: #fff;
    &:hover {
      color: #1e3959;
    }
    &.is-active {
      background-color: white !important;
      color: #1e3959 !important;
    }
  }
}
a.navbar-item {
  transition: all 0.5s ease;
}
a.navbar-item:focus:not(.no-hoverable),
a.navbar-item:focus-within:not(.no-hoverable),
a.navbar-item:hover:not(.no-hoverable),
a.navbar-item.is-active:not(.no-hoverable),
.navbar-link:focus:not(.no-hoverable),
.navbar-link:focus-within:not(.no-hoverable),
.navbar-link:hover:not(.no-hoverable),
.navbar-link.is-active:not(.no-hoverable) {
  color: $primary;
  /* background: transparentize($color: $primary, $amount: 0.9) !important; */
}
.picker {
  position: absolute !important;
}

@import "input.scss";
@import "bulma";
@import "~bulma-steps";
@import "~bulma-checkradio";
@import "~@creativebulma/bulma-tooltip";

a.navbar-item:not(.no-hoverable),
a.navbar-link:not(.no-hoverable) {
  height: fit-content !important;
  margin: auto 8px !important;
  border-radius: 3px !important;
}

.select:not(.is-multiple):not(.is-loading)::after,
.navbar-link:not(.is-arrowless)::after {
  border-width: 2px !important;
}

.as-cursor {
  cursor: pointer;
}
.is-sticky {
  position: sticky;
  top: 50px;
  z-index: 9 !important;
}
.main {
  padding-left: 250px;
  width: 100%;
}

.main-card {
  width: 70%;
  background-color: #fff;
  border-radius: 40px;
  transform: translateX(-40px);
}

.overflow-auto {
  overflow: auto;
}

.image-card {
  border-radius: 8px;
}

.logo {
  width: 110px;
}

/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: white;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $primary;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: transparentize($color: $primary, $amount: 0.4);
}

.container.is-full {
  height: 100%;
  padding: 16px;
}

.steps .step-item .step-marker {
  background-color: #fff;
  border-color: #fafafa;
}
.steps .step-item.is-primary.is-active .step-marker {
  background-color: #fff;
  border-color: $primary;
}

.steps .step-item.is-primary::before {
  transition: all 0.3s ease-in;
  background: $primary;
}

.root {
  height: 100vh;
  background-color: $backgroundColor !important;

  &.dark-theme {
    filter: invert(1) hue-rotate(180deg);

    img,
    picture,
    video {
      filter: invert(1) hue-rotate(180deg);
    }
  }
}

.app {
  display: flex;
  padding-top: 52px;

  .content {
    width: 100% !important;
    padding: 16px;
  }
}
.is-centered {
  margin: 0 auto;
}
.columns.is-vcentered {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.column {
  &.has-space-between {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  }
}

li {
  list-style: none;
}

a.pagination-link,
.pagination-previous,
.pagination-next {
  box-shadow: none;
  border: none;
  color: white;
  transition: all 0.2s ease;

  &:hover {
    background-color: #171e40;
    color: white;
  }

  &.is-current {
    border: none;
    background-color: $link;
  }
}
.is-fullwidth {
  width: 100% !important;
}
.shadow-sticky {
  position: sticky;
  top: 72px;
  box-shadow: rgba(220, 220, 220, 0.27) 0px 3px 6px 8px;
}
.select:not(.is-multiple):not(.is-loading)::after,
.navbar-link:not(.is-arrowless)::after {
  border-width: 1px !important;
}

.navbar-link:not(.is-arrowless)::after {
  border-color: $primary !important;
}

a {
  text-decoration: none !important;
}

.login {
  .notification {
    max-width: 500px;
    margin: 0 auto;
    box-shadow: rgba(220, 220, 220, 0.27) 0px 3px 6px 8px;
  }
}

@media only screen and (max-width: 700px) {
  .login {
    padding: 0px !important;

    .notification {
      max-width: 100%;
      margin: 0 auto;
      box-shadow: rgba(220, 220, 220, 0.27) 0px 3px 6px 8px;
    }
  }
}

@media all and (display-mode: fullscreen) {
  .hide-fullscreen {
    display: none !important;
  }
  .app {
    padding-top: 0px;
  }
  .main {
    padding-left: 0px;
  }
}
// TABS
.tabs.is-toggle {
  ul {
    li {
      padding: 7px;
      background: #f4f6fa;

      &:first-child {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
      }

      &:last-child {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
      }

      a {
        font-weight: 600;
        border-radius: 8px !important;
        border: none !important;
        &:hover {
          border: none !important;
          background-color: transparentize(
            $color: $primary,
            $amount: 0.8
          ) !important;
          color: $primary !important;

          &.is-active {
            background-color: $primary !important;
            color: $white !important;
            border: none !important;
          }
        }

        &.is-active {
          background-color: $primary;
          color: white !important;
          border: none !important;
        }
        border: none !important;
      }
    }
  }
}

.tabs {
  ul {
    border: none !important;
    li {
      padding-top: 7px;
      padding-bottom: 7px;
      background: transparent;

      a {
        color: #6e7191 !important;
        font-weight: 600;
        padding: 12px 16px;
        border-bottom: 2px solid #a0a3bd5d !important;

        &:hover {
          border-bottom: 2px solid $primary !important;
        }

        &.is-active {
          color: $primary !important;
          border-bottom: 2px solid $primary !important;
        }
      }
    }
  }
}

.relative {
  position: relative;
}
.absolute {
  position: absolute;
}

.tag,
.no-selectable {
  user-select: none;
}

.text {
  &.yellow {
    color: #ffe6b0;
  }
  &.green {
    color: #38ba51;
  }
  &.red {
    color: #ff415b;
  }
  &.orange {
    color: #fc7753;
  }
}
.is-pointer {
  cursor: pointer;
}
.h-full {
  height: 100%;
}
