.procedure-list-item {
  height: 70px;
  transition: all 0.3s ease;
  &:nth-child(2n) {
    background-color: #f4f6fa;
  }

  &:hover {
    background-color: #e4e7ed;
  }

  @media screen and (max-width: 768px) {
    height: auto;
  }
}
