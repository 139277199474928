.input,
input,
select,
textarea {
  border-width: 2px !important;
  border-radius: 8px !important;
  border-color: #e9e9e9 !important;
  box-shadow: none !important;
  &:active,
  &:focus {
    border-color: $darkGreen !important;
    box-shadow: none !important;
  }
}
